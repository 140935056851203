import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import WebDesign from '../Images/webDesign.png';
import WordPressWebsite from '../Images/wordpressWebsite.png';
import WebDevelopment from '../Images/webDevelopment.png';
import UiUxDesigns from '../Images/uiuxDesigns.png';
import SocialMediaHandling from '../Images/socialmediaHandling.png';
import LinkedInEnhancement from '../Images/linkedinEnhancement.png';
import ScrollAnimation from 'react-animate-on-scroll';
import {backInLeft, backInRight, backInUp} from 'animate.css';
import Tilt from 'react-parallax-tilt';


const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  media: {
    height: 300,
  },
});

const Services = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>

        <Typography variant="h2" align="center" className="heading">Services Provided</Typography>
        <Grid className="serviceSection" container spacing={4}>
        
        <Grid item lg={4} xs={12} md={6} align="center">
        <Tilt glareEnable={true} glareMaxOpacity={0.8} scale={1.02} transitionSpeed={2500}>
        <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={WebDesign}
                        title="Web Design"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" align="center">
                            Web Design
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ScrollAnimation>
        </Tilt>
        </Grid>
        

        <Grid item lg={4} xs={12} md={6} align="center">
        <Tilt glareEnable={true} glareMaxOpacity={0.8} scale={1.02} transitionSpeed={2500}>
        <ScrollAnimation animateIn="bounceInUp" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={WordPressWebsite}
                        title="WordPress Website"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" align="center">
                            WordPress Website
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ScrollAnimation>
        </Tilt>
        </Grid>

        <Grid item lg={4} xs={12} md={6} align="center">
        <Tilt glareEnable={true} glareMaxOpacity={0.8} scale={1.02} transitionSpeed={2500}>
        <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={WebDevelopment}
                        title="Web Development"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" align="center">
                            Web Development
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ScrollAnimation>
        </Tilt>
        </Grid>

        <Grid item lg={4} xs={12} md={6} align="center">
        <Tilt glareEnable={true} glareMaxOpacity={0.8} scale={1.02} transitionSpeed={2500}>
        <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={UiUxDesigns}
                        title="UI UX Designs"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" align="center">
                            UI/UX Designs
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ScrollAnimation>
        </Tilt>
        </Grid>

        <Grid item lg={4} xs={12} md={6} align="center">
        <Tilt glareEnable={true} glareMaxOpacity={0.8} scale={1.02} transitionSpeed={2500}>
        <ScrollAnimation animateIn="bounceInUp" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={SocialMediaHandling}
                        title="Social Media Handling"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" align="center">
                            Social Media Handling
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ScrollAnimation>
        </Tilt>
        </Grid>

        <Grid item lg={4} xs={12} md={6} align="center">
        <Tilt glareEnable={true} glareMaxOpacity={0.8} scale={1.02} transitionSpeed={2500}>
        <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={LinkedInEnhancement}
                        title="LinkedIn Enhancement"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" align="center">
                            LinkedIn Enhancement
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ScrollAnimation>
        </Tilt>
        </Grid>
        </Grid>
        </div>
    )
}

export default Services;
