import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Aniket from '../Images/Aniket.jpeg';

function MyBio() {
    return (
        <div className="myBio">
            <Typography variant="h3" align="center" className="heading">Aniket Kumar Sinha</Typography>
            <br/>
            <div align="center">
                {/* <Typography className="myBioText" color="primary" variant="h5">Image</Typography> */}
                <Grid container spacing={4}>
                    <Grid item lg={5} xs={12} md={5}>
                        <img className="MyBioImage" alt="Aniket Kumar Sinha" src={Aniket} />
                        <a href="https://drive.google.com/file/d/1uC7X88d4NYgSumtoxkVqllAgii7pAAdD/view?usp=sharing" target="_blank"><u><p>Download here</p></u></a>
                    </Grid>
                    <Grid item lg={7} xs={12} md={7}>
                        <div dangerouslySetInnerHTML={{ __html: "<iframe style='border: 1px solid rgba(0, 0, 0, 0.1);' width='600' height='450' src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F5HAZgI2plLCVGRn3knVbIN%2FAniket-Images%3Fnode-id%3D0%253A1' allowfullscreen></iframe>"}} />
                        <a href="https://www.figma.com/file/5HAZgI2plLCVGRn3knVbIN/Aniket-Images?node-id=0%3A1" target="_blank"><u><p>Figma file</p></u></a>
                    </Grid>
                </Grid>
                <br/><br/>
                <div className="myBioBio" align="left"> 
                    <Typography className="myBioText" color="primary" variant="h4">Bio</Typography>
                    <p>
                        I am Aniket Kumar Sinha. I a Cloud Consultant at Rapid Circle. I am the Gold Microsoft Learn Student Ambassador, Google Developer Student Club Lead'21, Microsoft Certified Professional, Certified MIE, and a Web Developer. I also create content on Instagram, YouTube, and Medium. I am a Community Guy and contribute a lot to communities.
                    </p>
                </div>
                <br/>
                <div className="myBioBio" align="left"> 
                    <Typography className="myBioText" color="primary" variant="h4">Headline/Designations</Typography>
                    <p>
                        Cloud Consultant at Rapid Circle | Gold Microsoft Learn Student Ambassador
                    </p>
                </div>
                <br/>
                <div className="myBioBio" align="left"> 
                    <Typography className="myBioText" color="primary" variant="h4">Social Handles</Typography>
                    <ul>
                        <li>
                            LinkedIn: {" "} 
                                <a href="https://www.linkedin.com/in/aniketkumarsinha/" target="_blank">in/aniketkumarsinha</a>
                        </li>
                        <li>
                            Instagram: {" "} 
                                <a href="https://www.instagram.com/aniketksinha/" target="_blank">@aniketksinha</a>
                        </li>
                        <li>
                            Twitter: {" "} 
                                <a href="https://twitter.com/AniketKSinha" target="_blank">@AniketKSinha</a>
                        </li>
                        <li>
                            Clubhouse: {" "} 
                                <a href="https://clubhouse.com/@aniketksinha" target="_blank">@aniketksinha</a>
                        </li>
                        <li>
                            Website: {" "} 
                                <a href="https://aniketkumarsinha.tech/" target="_blank">aniketkumarsinha.tech</a>
                        </li>
                        <li>
                            Mail Id: {" "} 
                                <a href="mailto:sinhaaniket32@gmail.com" target="_blank">sinhaaniket32@gmail.com</a>
                        </li>
                    </ul>  
                </div>
            </div>
        </div>
    )
}

export default MyBio
