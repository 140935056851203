import React from 'react';
import { makeStyles, useTheme, Typography, Card, CardContent, Paper, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SkillBar from 'react-skillbars';
import CountUp from 'react-countup';
import WorkIcon from '@material-ui/icons/Work';
import CodeIcon from '@material-ui/icons/Code';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Link } from "react-router-dom";
import EventNoteIcon from '@material-ui/icons/EventNote';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
});

const skills = [
    {
        "type": "Azure",
        "level": 70,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    },
    {
        "type": "Terraform",
        "level": 80,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    },
    {
        "type": "PowerShell",
        "level": 50,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    },
    {
        "type": "GitHub",
        "level": 90,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    },
    {
        "type": "Java",
        "level": 40,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    },
    {
        "type": "Html-CSS",
        "level": 65,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    },
    {
        "type": "JavaScript",
        "level": 70,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    },
    {
        "type": "React",
        "level": 60,
        "color": {
            "bar": "#00CC9B",
            "title": {
                "background": "#00CC9B"
            }
        }
    }
];

const About = () => {
    const classes = useStyles();

    return (
        <div className="aboutPage">
        <div className={classes.root}>
        <Typography variant="h2" align="center" >About Me</Typography>
            <Grid container spacing={4} className="about">
                <Grid item lg={6} xs={12} align="center" className="about-text">
                    <Typography variant="h6" display="block" >I am <span className="name">Aniket Kumar Sinha.</span></Typography>
                    <Typography variant="h6" >I am currently working as the Cloud Consultant at Rapid Circle. I am also a DevOps Person, YouTuber, Blogger, Content Creator and Public Speaker.</Typography>
                    <p><Typography variant="h6">I love to be part of communities and I enjoy connecting people. I am the Gold Microsoft Learn Student Ambassador and was the Google Developer Student Club Lead'21.</Typography></p>
                    <div className="btn">
                        <a href="mailto:contact@aniketkumarsinha.tech">
                        <Button variant="contained" color="primary" size="medium">Contact Me</Button>
                        </a>
                    </div>
                </Grid> 
                <Grid item lg={6} xs={12}>
                    <SkillBar skills={skills} height={'3vh'}/>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item lg={4} xs={12} align="center">
                    <WorkIcon />
                    <Typography variant="h2" display="block" >
                        <CountUp
                            end={5}
                            duration={5}
                        />
                    </Typography>
                    <Typography variant="h5" display="block">Experiences</Typography>
                </Grid>
                <Grid item lg={4} xs={12} align="center">
                    <CodeIcon />
                    <Typography variant="h2" display="block" >
                        <CountUp
                            end={100000}
                            duration={5}
                        />
                    </Typography>
                    <Typography variant="h5" display="block">Lines of Code</Typography>
                </Grid>
                <Grid item lg={4} xs={12} align="center">
                    <VerifiedUserIcon />
                    <Typography variant="h2" display="block" >
                        <CountUp
                            end={10}
                            duration={5}
                        />
                    </Typography>
                    <Typography variant="h5" display="block">Certifications</Typography>
                </Grid>
            </Grid>
            <br/><br/><br/><br/>
            <div align="center">
                <Link to="/talks-appearances">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<EventNoteIcon />}
                    >
                        Schedules | Talks | Appearances
                    </Button>
                </Link>
            </div>
        </div>
        </div>
    )
}

export default About