import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const columns = [
  { id: 'date', label: 'Date', minWidth: 100 },
  { id: 'topic', label: 'Topic', minWidth: 250 },
  {
    id: 'venue',
    label: 'Venue',
    minWidth: 170,
  },
  {
    id: 'records',
    label: 'Records',
    minWidth: 100,
  },
];

function createData(date, topic, venue, records) {
  return { date, topic, venue, records };
}

const rows = [
  createData( 
    '06/03/2022',
    'GDSC Lead Applications 2022 | Complete Procedure | Apply Now',
    <a href="https://www.youtube.com/c/AniketKumarSinha" target="_blank">Aniket's YouTube</a>,
    <a href="https://youtu.be/qNl84iH70cw" target="_blank">YouTube Video</a> 
  ), 
  createData( 
    '02/01/2022',
    'Benefits of being a College Student | 🤑Free Stuffs worth Lakhs 🤑',
    <a href="https://www.youtube.com/c/AniketKumarSinha" target="_blank">Aniket's YouTube</a>,
    <a href="https://youtu.be/CqEm_ZbqbA0" target="_blank">YouTube Video</a> 
  ), 
  createData( 
    '04/11/2021',
    'Best Investments Platforms for College Students',
    <a href="https://www.youtube.com/c/AniketKumarSinha" target="_blank">Aniket's YouTube</a>,
    <a href="https://youtu.be/TE2At5JpYE0" target="_blank">YouTube Video</a> 
  ), 
    createData( 
        '10/09/2021',
        'GDSC: The Beginning',
        <a href="https://gdsc.community.dev/b-k-birla-institute-of-engineering-technology-pilani/" target="_blank">GDSC BKBIET</a>,
        <a href="https://youtu.be/f9NjWRjz00U" target="_blank">Session Link</a> 
    ), 
    createData( 
        '27/08/2021',
        'Cloud 101',
        <a href="https://www.linkedin.com/company/mscpilani/" target="_blank">Microsoft Student Club Pilani</a>,
        <a href="https://youtu.be/H7KbtQQILKg" target="_blank">Session Link</a> 
    ), 
    createData( 
        '11/07/2021',
        'AMA on MLSA Program and Community Management(Host)',
        <a href="https://www.linkedin.com/company/mscpilani/" target="_blank">Microsoft Student Club Pilani</a>,
        <a href="https://youtu.be/uKfjlXTjJJA" target="_blank">Session Link</a> 
    ),  

    createData( 
        '28/05/2021',
        'Weaving the Web',
        <a href="https://discord.gg/XmMnEC8vYN" target="_blank">DSC BKBIET Discord Stage</a>,
        <a href="https://discord.gg/44xdbAzgFE" target="_blank">Stage Link</a> 
    ),

    createData( 
        '15/05/2021',
        'Pilani Talks | College . Communities . Hackathons(Host)',
        <a href="https://www.instagram.com/girlscript_pilani/" target="_blank">GirlScript Pilani IG Page</a>,
        <a href="https://www.instagram.com/tv/CO5gYbalZ9S/?utm_source=ig_web_button_share_sheet" target="_blank">Session Link</a> 
    ),

    createData( 
        '31/03/2021',
        "How to Deploy React app on GitHub?",
        <a href="https://www.youtube.com/channel/UCEDK0r0TfURa1vYZ4sMW0jw" target="_blank">Aniket's YouTube</a>,
        <a href="https://youtu.be/0b--zhGWBBU" target="_blank">YouTube Video</a> 
    ),

    createData( 
        '28/03/2021',
        "Java through Notepad & Command Prompt",
        <a href="https://www.youtube.com/channel/UCEDK0r0TfURa1vYZ4sMW0jw" target="_blank">Aniket's YouTube</a>,
        <a href="https://youtu.be/z1mHvjdal7M" target="_blank">YouTube Video</a> 
    ),

    createData( 
        '26/03/2021',
        '“Hello World!” in ReactJs',
        <a href="https://www.youtube.com/channel/UCEDK0r0TfURa1vYZ4sMW0jw" target="_blank">Aniket's YouTube</a>,
        <a href="https://youtu.be/uUpbXeqneos" target="_blank">YouTube Video</a> 
    ),

    createData( 
        '10/02/2021',
        'Solution Challenge Info Session',
        <a href="https://dsc.community.dev/bk-birla-institute-of-engineering-and-technology-pilani/" target="_blank">DSC BKBIET</a>,
        <a href="https://youtu.be/o7mES2kTtOs" target="_blank">Session Link</a> 
    ),

    createData( 
        '05/11/2020',
        "GitHub and It's Functionalities'",
        <a href="https://www.youtube.com/channel/UCEDK0r0TfURa1vYZ4sMW0jw" target="_blank">Aniket's YouTube</a>,
        <a href="https://youtu.be/1bxUvPxtKEc" target="_blank">YouTube Video</a> 
    ),

    createData( 
        '03/11/2020',
        'How to become Microsoft Learn Student Ambassador?',
        <a href="https://www.youtube.com/channel/UCEDK0r0TfURa1vYZ4sMW0jw" target="_blank">Aniket's YouTube</a>,
        <a href="https://youtu.be/gCDwJpKjvWM" target="_blank">YouTube Video</a> 
    ),

    createData( 
        '27/10/2020',
        'AMA on Imagine Cup(Host)',
        <a href="https://www.linkedin.com/company/mscpilani/" target="_blank">Microsoft Student Club Pilani</a>,
        <a href="https://youtu.be/EqnCKTLezn4" target="_blank">Session Link</a> 
    ),

    createData( 
        '16/10/2020',
        'Codigo-Fiesto | Build and Deploy Your First Website',
        <a href="ttps://www.linkedin.com/company/mscpilani/" target="_blank">Microsoft Student Club Pilani</a>,
        <a href="https://youtu.be/IvY25CuCBMM" target="_blank">Workshop Link</a> 
    ),

    createData( 
        '11/10/2020',
        'DSC Hacktoberfest Days',
        <a href="https://dsc.community.dev/bk-birla-institute-of-engineering-and-technology-pilani/" target="_blank">DSC BKBIET</a>,
        <a href="https://youtu.be/5hA25MI3u8Y" target="_blank">Session Link</a> 
    ),

    createData( 
        '03/10/2020',
        'AMA on MLH Fellowship(Host)',
        <a href="https://www.linkedin.com/company/mscpilani/" target="_blank">Microsoft Student Club Pilani</a>,
        <a href="https://youtu.be/vlQxiU7apfc" target="_blank">Session Link</a> 
    ),

    createData( 
        '02/10/2020',
        'Leveraging your LinkedIn',
        <a href="https://www.linkedin.com/company/mscpilani/" target="_blank">Microsoft Student Club Pilani</a>,
        <a href="https://youtu.be/nWjs6pL9qsY" target="_blank">Session Link</a> 
    ),

    createData( 
        '06/09/2020',
        'MLSA | Everything you should know about it!!!',
        <a href="https://www.linkedin.com/company/mscpilani/" target="_blank">Microsoft Student Club Pilani</a>,
        <a href="https://youtu.be/7uZphzn1rZg" target="_blank">Session Link</a> 
    ),

    createData( 
        '05/09/2020',
        'Panel Discussion with Achievers',
        <a href="https://tcol2020.com/" target="_blank">The Circle Of Legends</a>,
        <a href="https://youtu.be/VhXkl-VHwew" target="_blank">Session Link</a> 
    ),

    createData( 
        '08/08/2020',
        'Microsoft Learn Student Ambassador | Aniket Kumar Sinha',
        <a href="https://www.youtube.com/channel/UCEDK0r0TfURa1vYZ4sMW0jw" target="_blank">Aniket's YouTube</a>,
        <a href="https://youtu.be/fwMbKV6HDVg" target="_blank">YouTube Video</a> 
    ),

    createData( 
        '07/06/2020',
        'Introduction to JavaScript',
        <a href="#" target="_blank">Better World Community(Meet)</a>,
        <a href="#" target="_blank">N/A</a> 
    ),


];

const useStyles = makeStyles({
  table: {
      maxWidth: 1000,
  },
  root: {
    width: '100%',
  },
  head: {
     backgroundColor: "#00cc9b",
     fontSize: 20,
  },
  body: {
      fontSize: 15,
  },
});

export default function Schedules() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

  return (
    <div className="schedules" align="center">
    <div className={classes.root}>
      <TableContainer>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  bold
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell className={classes.body} key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination 
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
      <br/>
      <div className="inviteDiv" align="center">
                <p className="inviteMeText">Want to invite me?</p>
                <div style={{ height: 20 }}></div>
                <Link to="/aniket-bio">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<AccountCircleIcon />}
                    >
                        Get my details!
                    </Button>
                </Link>
            </div>            
    </div>
    </div>
  );
}
