import React from "react";
import "./Header.css";
import logo from "../Images/AKS.png";
import {BrowserRouter as Router,Link} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

const RespMenu = () => { // This function is for making the navbar responsive
  var x = document.getElementById("myTopnav");
  if (x.className === "header__main") {
    x.className += " responsive"; //adds this class if the burger icon is clicked 
  } else {
    x.className = "header__main";
  }
  var x = document.getElementById("myMenu");
  if (x.className === "header__menu") {
    x.className += " responsive";
  } else {
    x.className = "header__menu";
  }
}

const Header = () => {
  return (
    <>
		<div className="header__main" id="myTopnav">
			<div className="resp">
				<Link to="/">
				<div className="header__logo">
					<img src={logo} />
				</div>
				</Link>
				<div className="header__burger" onClick={RespMenu}>
                    <MenuIcon />
				</div>
			</div>
			<div className="header__menu" id="myMenu" onClick={RespMenu}>
				<Link to="/" className="nav__links"><span>Home</span></Link>
				<Link to="/about" className="nav__links"><span>About</span></Link>
				<Link to="/experience" className="nav__links"><span>Experience</span></Link>
				{/* <Link to="/services" className="nav__links"><span>Services</span></Link> */}
				<Link to="/portfolio" className="nav__links"><span>Portfolio</span></Link>
				<Link to="/blog" className="nav__links"><span>Blog</span></Link>
				<Link to="/contact" className="nav__links"><span>Contact</span></Link>
			</div>
		</div>
    </>
  );
};

export default Header;
