import React from 'react';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Grid from '@material-ui/core/Grid';
import { Typography, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing(1),
  },
}));


function PageNotFound() {
    const classes = useStyles();

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item lg={6} xs={12} md={8} align="center">
                    <Player
                        className="largeScreen404"
                        autoplay
                        loop
                        src="https://assets4.lottiefiles.com/private_files/lf30_5lxknspz.json"
                        style={{ height: "500px", width: "800px" }}
                    >
                        <Controls visible={false} />
                    </Player>
                    <Player
                        className="smallScreen404"
                        autoplay
                        loop
                        src="https://assets4.lottiefiles.com/private_files/lf30_5lxknspz.json"
                        style={{ height: "500px", width: "400px" }}
                    >
                        <Controls visible={false} />
                    </Player>
                </Grid>
                <Grid item lg={6} xs={12} md={4} align="center">
                <br/><br/><br/><br/><br/><br/>
                    <Typography component="div" color="primary">
                        <Box fontFamily="Lato" fontSize="h3.fontSize" m={1}>
                            PAGE NOT FOUND
                        </Box>
                    </Typography>
                    <div className="pageNotFoundContent" align="center">
                    <Link to="/">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<KeyboardBackspaceIcon />}
                    >
                        Back to Home Page
                    </Button>
                    </Link>
                    </div>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default PageNotFound
