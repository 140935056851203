import React from 'react';
import {AppBar, Container, Toolbar, Typography, Box, makeStyles} from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
});

const Footer = () => {
    const classes = useStyles();

    return (
        <div>
            <AppBar position="sticky bottom" color="primary" align="center">
                <div className="footer">
                        <div>
                            <a className="socialMedia" href="https://www.linkedin.com/in/aniketkumarsinha/" target="_blank"><LinkedInIcon style={{ color: "#222222" }} /></a>
                            <a className="socialMedia" href="https://www.youtube.com/c/AniketKumarSinha" target="_blank"><YouTubeIcon style={{ color: "#222222" }} /></a>
                            <a className="socialMedia" href="https://github.com/aniketkumarsinha" target="_blank"><GitHubIcon style={{ color: "#222222" }} /></a>
                            <a className="socialMedia" href="https://twitter.com/AniketKSinha" target="_blank"><TwitterIcon style={{ color: "#222222" }} /></a>
                            <a className="socialMedia" href="https://www.instagram.com/aniketksinha/" target="_blank"><InstagramIcon style={{ color: "#222222" }} /></a>
                        </div>
                        <Typography color='warning'>
                            Copyright © 2023 | Made with 💖
                        </Typography>
                        <Typography color='warning'>
                            <Box fontWeight="fontWeightBold">Aniket Kumar Sinha</Box>
                        </Typography>
                </div>
            </AppBar>
        </div>
    )
}

export default Footer
