import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Portfolio1 from '../Images/Portfolio1.png';
import SkillUnga from '../Images/SkillUnga.png';
import tfc from '../Images/tfc.png';
import sentinel from '../Images/sentinel.png';
import GetoGitHub from '../Images/Get-o-GitHub.png';
import StickNotes from '../Images/StickNotes.png';
import Calender from '../Images/Calender.png';
import ShopLaptop from '../Images/ShopLaptop.png';
import BicycleMela from '../Images/BicycleMela.png';
import artApp from '../Images/artApp.png';
import Restaurant from '../Images/Restaurant.png';
import KetaleBlogs from '../Images/Ketale-Blogs.png';
import Foodie from '../Images/Foodie.png';
import TicTakToe from '../Images/TicTakToe.png';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkIcon from '@material-ui/icons/Link';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ScrollAnimation from 'react-animate-on-scroll';
import {backInLeft, backInRight} from 'animate.css';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  media: {
    height: 180,
  },
});

const Portfolio = () => {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div className={classes.root}>

        <Typography variant="h2" align="center" className="heading">Portfolio</Typography>
        
        <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabs}
        textColor="primary"
        centered
        >
        {/* <Tab label="All" {...a11yProps(0)} />
        <Tab label="DevOps" {...a11yProps(1)} />
    <Tab label="Web Apps" {...a11yProps(2)} /> */}
        </Tabs> 
        
        
        {/* <TabPanel value={value} index={0}> */}
            <Grid container spacing={5}>
            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={tfc}
                        title="Deploy Azure Infrastructure using Terraform Cloud"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniketkumarsinha/azure-terraform-infrastructure" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={sentinel}
                        title="Implementing Policy-as-Code to Terraform workflow using Hashicorp Sentinel"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniketkumarsinha/terraform-sentinel-policy" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={SkillUnga}
                        title="SkillUnga"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://www.skillunga.com/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={GetoGitHub}
                        title="Get-o-GitHub"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/get-o-github/tree/master" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="https://github-account.web.app/signin" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>


            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={StickNotes}
                        title="Stick Notes"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/Sticky-Notes" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/Sticky-Notes/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>


            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={ShopLaptop}
                        title="ShopLaptop"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/Shop-Laptop/tree/master" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/Shop-Laptop/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>
            </Grid>
        {/* </TabPanel> */}


{/* 
        <TabPanel value={value} index={1}>
            <Grid container spacing={5}>
            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={Portfolio1}
                        title="Portfolio 1"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom className="portfolioname" variant="h5" component="h2" align="center">
                            Portfolio 1
                        </Typography> */}
                        {/* <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/aniket" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/aniket/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={SkillUnga}
                        title="SkillUnga"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            SkillUnga
                        </Typography> */}
                        {/* <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://www.skillunga.com/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid> */}

            {/* <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={GetoGitHub}
                        title="Get-o-GitHub"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            Get-o-GitHub
                        </Typography> */}
                        {/* <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/get-o-github/tree/master" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="https://github-account.web.app/signin" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid> */}


            {/* <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={StickNotes}
                        title="Stick Notes"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            Stick Notes
                        </Typography> *
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/Sticky-Notes" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/Sticky-Notes/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={ShopLaptop}
                        title="ShopLaptop"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            ShopLaptop
                        </Typography> 
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/Shop-Laptop/tree/master" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/Shop-Laptop/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInRight" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={BicycleMela}
                        title="Bicycle Mela"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            Bicycle Mela
                        </Typography> *
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="#"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="https://bicyclemela.wordpress.com/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid> 


            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={Restaurant}
                        title="Restaurant Website"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            Restaurant Website
                        </Typography> 
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/mod5_sol" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/mod5_sol/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={KetaleBlogs}
                        title="Ketale Blogs"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            Ketale Blogs
                        </Typography> 
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/Ketale-Blogs" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/Ketale-Blogs/Ketale%20Blogs/index.html" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>

            <Grid item lg={4} xs={12} md={6} align="center">
            <ScrollAnimation animateIn="bounceInLeft" delay={2}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={TicTakToe}
                        title="Tic Tac Toe"
                    />
                    <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="h2" align="center">
                            Tic Tac Toe
                        </Typography> 
                        <Typography gutterBottom variant="h6" component="h3" align="center">
                            <a className="portfoliolink" href="https://github.com/aniket-sinha8/Tic-Tac-Toe/tree/master" target="_blank"><GitHubIcon style={{ color: "#007F60" }} /></a>
                            <a className="portfoliolink" href="http://aniket-sinha8.github.io/Tic-Tac-Toe/" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            </ScrollAnimation>
            </Grid>
            </Grid>    
        </TabPanel>  


        // <TabPanel value={value} index={2}>
        //     <Grid container spacing={5}>
        //     <Grid item lg={4} xs={12} md={6} align="center">
        //     <ScrollAnimation animateIn="bounceInLeft" delay={2}>
        //     <Card className={classes.root}>
        //         <CardActionArea>
        //             <CardMedia
        //                 component="img"
        //                 className={classes.media}
        //                 image={Calender}
        //                 title="Calender App UI"
        //             />
        //             <CardContent>
        //                 {/* <Typography gutterBottom variant="h5" component="h2" align="center">
        //                     Calender UI
        //                 </Typography> 
        //                 <Typography gutterBottom variant="h6" component="h3" align="center">
        //                     <a className="portfoliolink" href="https://www.figma.com/file/WmQBtknI6SFUM5dwzkhZ59/Calender?node-id=0%3A1" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
        //                 </Typography>
        //             </CardContent>
        //         </CardActionArea>
        //     </Card>
        //     </ScrollAnimation>
        //     </Grid>

        //     <Grid item lg={4} xs={12} md={6} align="center">
        //     <ScrollAnimation animateIn="bounceInLeft" delay={2}>
        //     <Card className={classes.root}>
        //         <CardActionArea>
        //             <CardMedia
        //                 component="img"
        //                 className={classes.media}
        //                 image={artApp}
        //                 title="Art App UI"
        //             />
        //             <CardContent>
        //                 {/* <Typography gutterBottom variant="h5" component="h2" align="center">
        //                     Art App UI
        //                 </Typography> 
        //                 <Typography gutterBottom variant="h6" component="h3" align="center">
        //                     <a className="portfoliolink" href="https://www.figma.com/file/NtsNjMYkeUq4FuOw5I8G9p/Art-App?node-id=0%3A1" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
        //                 </Typography>
        //             </CardContent>
        //         </CardActionArea>
        //     </Card>
        //     </ScrollAnimation>
        //     </Grid>

        //     <Grid item lg={4} xs={12} md={6} align="center">
        //     <ScrollAnimation animateIn="bounceInLeft" delay={2}>
        //     <Card className={classes.root}>
        //         <CardActionArea>
        //             <CardMedia
        //                 component="img"
        //                 className={classes.media}
        //                 image={Foodie}
        //                 title="Foodie App UI"
        //             />
        //             <CardContent>
        //                 {/* <Typography gutterBottom variant="h5" component="h2" align="center">
        //                     Foodie App UI
        //                 </Typography> 
        //                 <Typography gutterBottom variant="h6" component="h3" align="center">
        //                     <a className="portfoliolink" href="https://www.figma.com/file/mA3ljK6ygQRHgTDKHIwwFJ/Foodie?node-id=0%3A1" target="_blank"><LinkIcon style={{ color: "#007F60" }} /></a>
        //                 </Typography>
        //             </CardContent>
        //         </CardActionArea>
        //     </Card>
        //     </ScrollAnimation>
        //     </Grid>
        //     </Grid>
        // </TabPanel>
        //  */}
        </div>
    )
}

export default Portfolio;
