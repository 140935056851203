import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '@material-ui/icons/Work';
import StarIcon from '@material-ui/icons/Star';
import Grid from '@material-ui/core/Grid';
import MLSA from '../Images/MLSA.png';
import DSC from '../Images/dscl.png';
import MSCP from '../Images/MSCP.png';
import GS from '../Images/GS.png';
import CSI from '../Images/CSI.png';
import ACM from '../Images/ACM.png';
import PREPBYTES from '../Images/PREPBYTES.png';
import CN from '../Images/CN.jpg';
import CB from '../Images/CB.jpg';
import COC from '../Images/COC.jpg';
import VERTOCITY from '../Images/VERTOCITY.png';
import POSTMAN from '../Images/POSTMAN.png';
import ScrollAnimation from 'react-animate-on-scroll';
import {backInLeft, backInRight} from 'animate.css';













const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
});


const Experience = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h2" align="center" className="heading">Experiences</Typography>

            <div className="work">
                <VerticalTimeline>
                <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(30, 226, 179)', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(30, 226, 179)' }}
                        date="March, 2022 - Present"
                        iconStyle={{ background: 'rgb(0, 166, 126)', color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">Cloud Consultant</h3>
                        <h4 className="vertical-timeline-element-subtitle">Rapid Circle</h4>
                        <p>
                            Cloud Computing, Microsoft Azure, PowerShell, Terraform, Cloud Infrastructure, Terraform Cloud, Hashicorp Sentinel
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(30, 226, 179)', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(30, 226, 179)' }}
                        date="July, 2021 - October, 2021"
                        iconStyle={{ background: 'rgb(0, 166, 126)', color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">Community Manager Intern</h3>
                        <h4 className="vertical-timeline-element-subtitle">Rancho Labs</h4>
                        <p>
                            Community Building, Community Management
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(30, 226, 179)', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(30, 226, 179)' }}
                        date="June, 2020 - September, 2020"
                        iconStyle={{ background: 'rgb(0, 166, 126)', color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">Web Development Intern</h3>
                        <h4 className="vertical-timeline-element-subtitle">InCampus Ltd.</h4>
                        <p>
                            Website Development, User Interface Designer, Database Management 
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(30, 226, 179)', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(30, 226, 179)' }}
                        date="June, 2020 - August, 2020"
                        iconStyle={{ background: 'rgb(0, 166, 126)', color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">Business Development Intern</h3>
                        <h4 className="vertical-timeline-element-subtitle">Alien Brains Education</h4>
                        <p>
                            Business Development, Community Management, College TieUps
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        iconStyle={{ background: 'rgb(0, 166, 126)', color: '#fff' }}
                        icon={<StarIcon />}
                    />
                </VerticalTimeline>
            </div>

            <hr />

            <div className="communities">
            <Typography variant="h4" align="center" className="communityHeading">Communities</Typography>


                <Grid container spacing={3}>
                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInLeft" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="MLSA" className="communityImage" src={MLSA} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>MLSA</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInLeft" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="DSC BKBIET" className="communityImage" src={DSC} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>GDSC</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInRight" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="MSC Pilani" className="mscp" src={MSCP} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>MSC PILANI</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInRight" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="GirlScript Pilani" className="gsp" src={GS} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>GIRLSCRIPT PILANI</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInLeft" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="CSI" className="csi" src={CSI} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>CSI BKBIET</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>
                    
                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInLeft" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="ACM" className="csi" src={ACM} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>ACM BKBIET</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInRight" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="PREPBYTES" className="csi" src={PREPBYTES} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>PREPBYTES</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInRight" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="CN" className="csi" src={CN} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>CODING NINJAS</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInLeft" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="CB" className="csi" src={CB} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>CODING BLOCKS</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInLeft" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="COC" className="csi" src={COC} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>CODE FOR CAUSE</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInRight" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="VERTOCITY" className="csi" src={VERTOCITY} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>VERTOCITY</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>

                    <Grid item lg={3} xs={12} md={4} align="center">
                    <ScrollAnimation animateIn="bounceInRight" delay={2}>
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <img alt="POSTMAN" className="postman" src={POSTMAN} />
                                </div>
                                <div className="flip-box-back">
                                    <h2>POSTMAN</h2>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Experience
