import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ContactImg from '../Images/contactImg.png';
import Aniket from '../Images/AniketSinha.jpeg';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Contact = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h2" align="center" className="heading">Contact Me</Typography>
            <Grid container spacing={4}>
                <Grid item lg={5} xs={12} md={4} align="center">
                    <img alt="Contact Me" className="ContactImg" src={ContactImg}/>
                </Grid>
                <Grid item lg={7} xs={12} md={8} align="center">
                    <br/>
                    <div className="card">
                        <img src={Aniket} alt="Aniket Kumar Sinha" className="contactAniketImage"  />
                        <hr/>
                        <h2>Aniket Kumar Sinha</h2>
                        <span className="contactTitle">Cloud | Devrel | Community</span>
                        <p>India</p>
                        <br/>
                        <Grid container spacing={1}>
                            <Grid item lg={6} xs={6} md={6} align="center">
                                <a href="tel:916203961808">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    startIcon={<CallIcon />}
                                >
                                    Call
                                </Button>
                                </a>
                            </Grid>
                            <Grid item lg={6} xs={6} md={6} align="center">
                                <a href="mailto:sinhaaniket32@gmail.com">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    startIcon={<EmailIcon />}
                                >
                                    Mail
                                </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>     
                </Grid>
            </Grid>
            <div className="inviteDiv" align="center">
                <p className="inviteMeText">Want to invite me?</p>
                <Link to="/aniket-bio">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<AccountCircleIcon />}
                    >
                        Get my details!
                    </Button>
                </Link>
            </div>            
        </div>
    )
}

export default Contact;
