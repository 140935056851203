import logo from './logo.svg';
import './App.css';
// import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Header from './Components/Header';
// import BmcWidget from './Components/BmcWidget';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
// import Loader from "react-loader-spinner";

import Home from './Pages/Home/Home';
import About from './Pages/About';
import Experience from './Pages/Experience';
import Services from './Pages/Services';
import Portfolio from './Pages/Portfolio';
import Blog from './Pages/Blog';
import Contact from './Pages/Contact';
import Schedules from './Pages/Schedules';
import PageNotFound from './Pages/PageNotFound';
import MyBio from './Pages/MyBio';

// import Darkmode from 'darkmode-js';

// const options = {
//   // bottom: '64px', // default: '32px'
//   // right: 'unset', // default: '32px'
//   // left: '32px', // default: 'unset'
//   time: '0.5s', // default: '0.3s'
//   mixColor: '#fff', // default: '#fff'
//   backgroundColor: '#fff',  // default: '#fff'
//   buttonColorDark: '#100f2c',  // default: '#100f2c'
//   buttonColorLight: '#fff', // default: '#fff'
//   saveInCookies: false, // default: true,
//   label: '🌓', // default: ''
//   autoMatchOsTheme: true // default: true
// }
 
// const darkmode = new Darkmode(options);
// darkmode.showWidget();



const theme = createMuiTheme({
  palette: {
    // type: 'dark',
    primary: {
      main: '#00CC9B',
    },
    secondary: {
      main: '#ffffff',
    },
    warning: {
      main: '#16A17F',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
    {/* <Loader
        center
        type="Puff"
        color="#00cc9b"
        height={100}
        width={100}
        timeout={3000} //3 secs
      /> */}
      {/* <Navbar />
      <Footer /> */}

      <BrowserRouter>
      <Header />
      <div style={{ height: 100 }}></div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/experience">
          <Experience />
        </Route>
        {/* <Route path="/services">
          <Services />
        </Route> */}
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/talks-appearances">
          <Schedules />
        </Route>
        <Route path="/aniket-bio">
          <MyBio />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
      <div style={{ height: 100 }}></div>
      <Footer />
      {/* <BmcWidget /> */}
    </BrowserRouter>
    </div>
    </ThemeProvider>
  );
}

export default App;
