import React from 'react';
import './Style.css';
import { makeStyles, useTheme, Typography, Card, CardContent, Paper, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import homepageimage from './HomePagePhoto.png';
import ReactTypingEffect from 'react-typing-effect';
import InstagramEmbed from 'react-instagram-embed';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
});

const Home = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={-5}>
                <Grid item lg={6} md={6} xs={12} align="left" className="home-text">
                    <Typography variant="h2" display="block" >Hi 👋</Typography>
                    <Typography variant="h2" >I am</Typography>
                    <Typography variant="h1" color='primary'>Aniket</Typography>
                    <Typography variant="h3" ><ReactTypingEffect className="typingeffect" text={['CLOUD CONSULTANT', 'DEVOPS GUY', 'DEVELOPER', 'YOUTUBER', 'BLOGGER', 'CONTENT CREATOR', 'PUBLIC SPEAKER']} speed={100} eraseDelay={500}/></Typography>
                    <div className="btn">
                        <a href="https://drive.google.com/file/d/1Q2bNoE9gT6etpUKVT40vO4meFqgI9tJH/view?usp=sharing" target="_blank" download="Aniket_Kumar_Sinha_Resume.pdf">
                        <Button variant="contained" text-color='secondary' color="primary" size="medium">Resume</Button>
                        </a>
                    </div>
                </Grid> 
                <Grid item lg={6} md={6} xs={12}>
                    <img alt="Aniket" className="homepageimage" src={homepageimage} />
                </Grid>
            </Grid>
        </div>
    )
}

export default Home
